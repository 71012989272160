<template>
  <div id="verificationSite">
    <div>
      <img src="../../assets/icons/check.svg" v-if="isVerified" class="check">
      <img src="../../assets/icons/cross.svg" v-else class="cross">
    </div>
    <div class="info">
      <h1>{{ title }}</h1>
      <p>
        <slot></slot>
      </p>
    </div>
    <PlainButton :button-function="()=>{$router.push('/')}" :scale-by-child="true" >Zur Hauptseite</PlainButton>
  </div>
</template>

<script>
import PlainButton from "@/components/Buttons/PlainButton";
export default {
  name: "Verification",
  components: {PlainButton},
  props: {
    isVerified: Boolean,
    title: String,
  },

}
</script>

<style scoped lang="scss">
@import "src/styles/themes";

* {
  font-family: 'Poppins', sans-serif;

}



.check {
  padding: 16px;
  border-radius: 100%;
  background-color: hsla(153, 47%, 49%, 0.15);
}

.cross {
  padding: 16px;
  border-radius: 100%;
  background-color: hsla(0, 61%, 58%, 0.15);
}

#verificationSite {
  padding: 64px 512px;
  gap: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include theme() {
    background-color: theme-get('background-1');
  }

  height: 100%;

}


.info {
  h1 {
    margin-top:0;
    margin-bottom: 32px;
    line-height: 100%;
    font-size: 4em;
    @include theme() {
      color: theme-get('header-1');
    }
    font-weight: 600;
  }

  div {

    @include theme() {
      color: theme-get('text-1');
    }

  }

  font-size: 1.5rem;
  @include theme() {
    color: theme-get('text-2');
  }

}

@media screen and (max-width: 1024px){
  #verificationSite {
    padding: 64px 128px;
  }
}
@media screen and (max-width: 768px){
  #verificationSite {

    .info{
      h1{
        font-size: 2.5em;
      }
      p{
        font-size: 1em;
      }
    }
    padding: 64px 64px;
  }
}
@media screen and (max-width: 480px){
  #verificationSite {
    .info{
      h1{
        font-size: 2em;
      }
      p{
        font-size: 0.75em;
      }
    }
    padding: 64px 32px;
  }
}
</style>
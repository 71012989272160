<template>
  <div id="discordVerfication">
    <Verification title="Discord bestätigt!" :isVerified="true">
      Dein Discord Account wurde bestätigt! Vergiss nicht, deine E-Mail Adresse zu verifizieren, falls du das noch nicht gemacht hast.
    </Verification>
  </div>
</template>

<script>
import Verification from "../../components/SiteComponents/Verification";

export default {
  name: "MailVerficiation",
  components:{Verification}
}
</script>

<style scoped>

</style>